<template>
	<div>
		<!-- {{ lote }} -->
	<b-overlay :show="isLoading">
		<b-table-simple sticky-header class="position-relative" :style="{ maxHeight: '60vh' }">
			<b-thead>
				<b-tr class="text-center">
					<b-th v-for="(item, key) in headTable" :key="key" :style="{ padding: 0 }" class="p-1">
						<div class="d-flex align-items-center justify-content-center">
							<img class="icon-head-posturas" :src="item.image" alt="" />
							<p class="mb-0">
								{{ item.text }}
							</p>
						</div>
					</b-th>
				</b-tr>
			</b-thead>

			<b-tbody>
				<template>
					<b-tr v-for="(postura, index) in posturas" class="text-center" :key="index">
						<b-td class="text-center">
							{{ postura.code }}
						</b-td>

						<b-td class="text-center">
							<SpecimenPlate
								:specimen="{
									plate: postura.madrilla.plate,
									alias: postura.madrilla.alias,
									id: postura.madrilla.id,
									thumb: postura.madrilla.thumb,
									image: postura.madrilla.image,
								}"
							/>
						</b-td>

						<b-td class="text-center">
							<SpecimenPlate
								:specimen="{
									plate: postura.padrillo.plate,
									alias: postura.padrillo.alias,
									id: postura.padrillo.id,
									thumb: postura.padrillo.thumb,
									image: postura.padrillo.image,
								}"
							/>
						</b-td>

						<b-td class="text-center">
							{{ postura.ing }}
						</b-td>

						<b-td class="text-center">
							{{ postura.inf }}
						</b-td>

						<b-td class="text-center">
							{{ postura.que }}
						</b-td>

						<b-td class="text-center">
							{{ postura.inc }}
						</b-td>

						<b-td class="text-center">
							{{
								lote.stage > 2
									? (((postura.ing - postura.inf - postura.que) / postura.ing) * 100).toFixed(1) + "%"
									: "---"
							}}
						</b-td>

						<b-td class="text-center">
							{{ lote.stage > 3 ? postura.nac : "---" }}
						</b-td>

						<b-td class="text-center">
							{{ lote.stage > 3 ? postura.no_n : "---" }}
						</b-td>

						<b-td class="text-center">
							{{ lote.stage > 3 ? postura.viv : "---" }}
						</b-td>

						<b-td class="text-center">
							{{ lote.stage > 3 ? postura.deads : "---" }}
						</b-td>

						<b-td class="text-center">
							{{ lote.stage > 3 ? postura.depu : "---" }}
						</b-td>

						<b-td class="text-center">
							{{
								lote.stage > 5
									? postura.viv == 0
										? `0%`
										: ((postura.viv / postura.nac) * 100).toFixed(1) + "%"
									: "---"
							}}
						</b-td>

						<b-td class="text-center">
							<div v-if="postura.cintillos.id" class="container-cintillos">
								<CombChickenlegsCintillos
									v-if="postura.cintillos.id"
									:postura="{
										chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="1"
									is_in_posturas
								/>
								<CombChickenlegsCintillos
									v-if="postura.cintillos.id"
									:postura="{
										chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: 1,
									}"
									is_in_posturas
									:chickenleg_wich_leg="2"
								/>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="posturas.length == 0">
						<b-td class="text-center" colspan="100%">No hay registros para mostrar</b-td>
					</b-tr>
				</template>
			</b-tbody>
			<!-- <b-tfoot>
				<b-tr>
					<b-th class="p-1 text-center"> </b-th>
					<b-th class="p-1 text-center"> TOTAL </b-th>
				</b-tr>
			</b-tfoot> -->
		</b-table-simple>
	</b-overlay>
	</div>
</template>

<script>
import lotesService from "@/services/lotes.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import posturasService from "@/services/posturas.service";
import CombChickenlegsCintillos from "../components/CombChickenlegsCintillos.vue";

export default {
	name: "PosturasListByLote",
	props: {
		lote: {
			type: Object,
			default: () => {},
		},
		specimen: {
			type: Object,
			default: () => ({
				id: null,
				category: null,
			}),
		},
	},
	components: {
		SpecimenPlate,
		CombChickenlegsCintillos
	},
	data() {
		return {
			headTable: [
				{ text: "POSTURA", value: "postura" },
				{ text: "MADRILLA", value: "madrilla" },
				{
					text: "PADRILLO",
					value: "padrillo",
				},
				{
					text: "ING",
					value: "ing",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "INF",
					value: "inf",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "que",
					value: "que",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "inc",
					value: "inc",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "fer",
					value: "fer",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "nac",
					value: "nac",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "no n",
					value: "no n",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "viv",
					value: "viv",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "mue",
					value: "mue",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "dep",
					value: "dep",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "efe",
					value: "efe",
					image: "/lotes/lote-grid-1.svg",
				},
				{
					text: "cintillo",
					value: "cintillo",
					image: "/lotes/lote-grid-1.svg",
				},
			],
			posturas: [],
			indexType: 0,
			item: {},
			status_stage: 0,
			isLoading: false,
		};
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			this.isLoading = true
			if (this.specimen.id) {
				this.posturas = await posturasService.fetchPostureBySpecimenCategory(
					this.specimen.category,
					this.specimen.id
				);
				return;
			}

			const resp = await lotesService.getPosturasByLoteId({
				lote_id: this.lote.lote_id,
			});

			this.posturas = resp.map((item) => {
				let cintillos = {
					...item.cintillos,
					chickenleg_wich_leg:
						item.cintillos &&
						(item.cintillos.wich_leg == "IZQUIERDA" ? 1 : item.cintillos.wich_leg == "DERECHA" ? 2 : 3),
				};
				return {
					...item,
					cintillos: item.cintillos ? cintillos : null,
					user_change_status: item.user_change_status ? JSON.parse(item.user_change_status) : null,
				};
			});
			this.isLoading = false
		},
	},
};
</script>
