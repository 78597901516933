var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-table-simple',{staticClass:"position-relative",style:({ maxHeight: '60vh' }),attrs:{"sticky-header":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},_vm._l((_vm.headTable),function(item,key){return _c('b-th',{key:key,staticClass:"p-1",style:({ padding: 0 })},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"icon-head-posturas",attrs:{"src":item.image,"alt":""}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.text)+" ")])])])}),1)],1),_c('b-tbody',[[_vm._l((_vm.posturas),function(postura,index){return _c('b-tr',{key:index,staticClass:"text-center"},[_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(postura.code)+" ")]),_c('b-td',{staticClass:"text-center"},[_c('SpecimenPlate',{attrs:{"specimen":{
								plate: postura.madrilla.plate,
								alias: postura.madrilla.alias,
								id: postura.madrilla.id,
								thumb: postura.madrilla.thumb,
								image: postura.madrilla.image,
							}}})],1),_c('b-td',{staticClass:"text-center"},[_c('SpecimenPlate',{attrs:{"specimen":{
								plate: postura.padrillo.plate,
								alias: postura.padrillo.alias,
								id: postura.padrillo.id,
								thumb: postura.padrillo.thumb,
								image: postura.padrillo.image,
							}}})],1),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(postura.ing)+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(postura.inf)+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(postura.que)+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(postura.inc)+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 2 ? (((postura.ing - postura.inf - postura.que) / postura.ing) * 100).toFixed(1) + "%" : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 3 ? postura.nac : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 3 ? postura.no_n : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 3 ? postura.viv : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 3 ? postura.deads : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 3 ? postura.depu : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.lote.stage > 5 ? postura.viv == 0 ? "0%" : ((postura.viv / postura.nac) * 100).toFixed(1) + "%" : "---")+" ")]),_c('b-td',{staticClass:"text-center"},[(postura.cintillos.id)?_c('div',{staticClass:"container-cintillos"},[(postura.cintillos.id)?_c('CombChickenlegsCintillos',{attrs:{"postura":{
									chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
									chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
									cintillo_color: postura.cintillos.hex_1,
									cintillo_description: postura.cintillos.description_1,
									second_color_color: postura.cintillos.hex_2,
									second_color_description: postura.cintillos.description_2,
									total_minus_infertile_eggs: 1,
								},"chickenleg_wich_leg":1,"is_in_posturas":""}}):_vm._e(),(postura.cintillos.id)?_c('CombChickenlegsCintillos',{attrs:{"postura":{
									chickenleg_wich_leg: postura.cintillos.chickenleg_wich_leg,
									chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
									cintillo_color: postura.cintillos.hex_1,
									cintillo_description: postura.cintillos.description_1,
									second_color_color: postura.cintillos.hex_2,
									second_color_description: postura.cintillos.description_2,
									total_minus_infertile_eggs: 1,
								},"is_in_posturas":"","chickenleg_wich_leg":2}}):_vm._e()],1):_vm._e()])],1)}),(_vm.posturas.length == 0)?_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v("No hay registros para mostrar")])],1):_vm._e()]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }