<template>
	<b-modal
		size="lg"
		v-model="isActive"
		centered
		hide-footer
		@hidden="handleHidden"
	>
		<template #modal-header="{ close }">
			<h5 class="m-0">
				<span class="d-flex">
					{{ eggType }} 
					<span class="counter">{{ total }}</span>
					/ Lote 
					{{ info.code || '---'}}
				</span>
			</h5>
			<b-button variant="primary" class="button-close" @click="close">
				x
			</b-button>
		</template>
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh" striped>
				<b-thead class="text-center">
					<b-tr>
						<b-th>Postura</b-th>
						<b-th>Padrillo</b-th>
						<b-th>Madrilla</b-th>
						<b-th>
							<span class="d-flex">
								Huevos
								<font-awesome-icon icon="pl-25 fa-solid fa-egg" size="lg" class="text-primary" style="margin-left: 5px;"/>
							</span>
						</b-th>
					</b-tr>
					<b-tr v-if="eggs.length < 0">
						<b-td class="text-center" colspan="3">No hay registros para mostrar</b-td>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in eggs" :key="idx">
						<b-td class="text-center">
							{{ row.postura_code }}
						</b-td>
						<b-td>
							<SpecimenPlate :specimen="row.padrillo" />
						</b-td>
						<b-td>
							<SpecimenPlate :specimen="row.madrilla" />
						</b-td>
						<b-td>
							{{ row.quantity }}
						</b-td>
					</b-tr>
					<b-tr v-if="eggs.length == 0">
						<b-td class="text-center" colspan="3">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import lotesService from "@/services/lotes.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	name: 'EggsDetailModal',
	components: { SpecimenPlate },
	props: { show: Boolean, info: Object, type: String },
	data: () => ({
		isActive: false,
		isLoading: false,
		eggs: [],
		total: 0,
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.eggs = []
			this.total = 0
			this.$emit("closing")
		},
		async getEggsByTypeAndLote() {
			this.isLoading = true
			const { data } = await lotesService.getEggsByTypeAndLote({ id: this.info.id, type: this.type })
			this.eggs = data
			this.total = data.reduce((a, b) => a + parseInt(b.quantity), 0)
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getEggsByTypeAndLote();
			}
		},
	},
	computed: {
		eggType() {
			const eggTypes = {
				ing: 'Huevos Ingresados',
				inf: 'Huevos Infertiles',
				que: 'Huevos Quemados',
				no_n: 'Huevos No Nacidos',
				inc: 'Huevos Incubados',
				nac: 'Huevos Nacidos'
			};
			return eggTypes[this.type] || '';
		}
	},
}
</script>
<style scoped>
.counter {
	height: 1.2rem;
	min-width: 1.2rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	margin-left: 3px;
	margin-right: 3px;
	background: var(--primary);
	border-radius: 0.2rem;
	font-weight: bolder;
	font-size: 12px;
}
.button-close {
    padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
    border-radius: 0.357rem;
    background: #fff;
}
</style>
